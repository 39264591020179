@import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:wght@700&family=Spline+Sans:wght@400;600;700&display=swap');

body {
    margin: 0;
  }
  html,
  body,
  #root {
    height: 100%;
    background: #0e1129;
  }
  